import React from 'react';
import '../App.css';
import { Container } from '@material-ui/core';
import { Api } from '../api/Api';
import { CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';

class Recipes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipes: [],
      error: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.getRecipes();
  }

  getRecipes = async () => {
    let api = new Api();

    try {
      this.setState({
        loading: false,
        recipes: await api.getUserRecipes(),
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: api.error,
      });
    }
  };

  render() {
    if (!this.state.loading) {
      return (
        <Container className="Container">
          <h1>Your recipes</h1>
          <Alert
            variant="danger"
            show={this.state.error.length > 0}
            transition={null}
          >
            {this.state.error}
          </Alert>
          <div>
            <Table responsive hover>
              <tbody>
                {this.state.recipes.map((item) => (
                  <tr key={item.uuid}>
                    <td className="tdLeftStyleRecipes"
                      onClick={() =>
                        this.props.history.push('/recipes/' + item.uuid)
                      }
                    >
                      {item.name}
                    </td>
                    <td className="tdRightStyleRecipes">
                      <EditIcon className="hover"/>
                    </td>
                    <td className="tdRightStyleRecipes">
                      <DeleteIcon className="hover"/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
      );
    } else {
      return (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      );
    }
  }
}

export default Recipes;
