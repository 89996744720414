import React from 'react';
import '../App.css';
import { Navbar, Nav } from 'react-bootstrap';
import fbConfig from '../firebaseConfig';
import { Link } from 'react-router-dom';

class Header extends React.Component {
  render() {
    return (
      <Navbar bg="light" expand="sm">
        <Navbar.Brand>Recipes To Make</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="container-fluid">
            <Nav.Item>
              <Link to="/" className="navLink">
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/recipes" className="navLink">
                Recipes
              </Link>
            </Nav.Item>
            <Nav.Item className="nav">
              <Link to="/login" onClick={() => fbConfig.auth().signOut()}>
                Sign Out
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
