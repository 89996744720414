import * as firebase from 'firebase/app';
import 'firebase/auth';

const fbConfig = firebase.initializeApp({
  apiKey: 'AIzaSyCmhEUBPhPqnF7vdCvn-zRI2QmG34RtyMo',
  authDomain: 'recipes-to-make.firebaseapp.com',
  databaseURL: 'https://recipes-to-make.firebaseio.com',
  projectId: 'recipes-to-make',
  storageBucket: 'recipes-to-make.appspot.com',
  messagingSenderId: '803774194222',
  appId: '1:803774194222:web:85f0c15321ed8be57335bf',
  measurementId: 'G-DCP2D5KQZY',
});

export default fbConfig;
