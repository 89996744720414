import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import fbConfig from '../firebaseConfig';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { Container } from '@material-ui/core';

const SignUp = ({ history }) => {
  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await fbConfig
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);
        history.push('/');
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  return (
    <div className="loading">
      <Container className="Container">
        <h2>Sign up</h2>
        <Form className="Form" onSubmit={handleSignUp}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control name="email" type="email" placeholder="Email" />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Button variant="info" type="submit">
            Sign Up
          </Button>
          <p>Already have an account?</p>
          <Link variant="link" to="/login">
            Log In
          </Link>
        </Form>
      </Container>
    </div>
  );
};

export default withRouter(SignUp);
