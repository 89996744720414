import fbConfig from '../firebaseConfig';
import { config } from '../config';

export class Api {
  error = '';

  codeToUserFacingMessage = {
    100: 'This account already exists.',
    101: 'There was an issue saving your data, please try again.',
    102: 'There was an issue retrieving your data, please try again.',
    103: 'You are not authorized.',
    104: 'There was an issue with your data, please try again.',
    105: 'Please refresh the page and try again.',
    106: 'Only one pantry allowed per user.',
  };

  clearError() {
    this.error = '';
  }

  async getUserRecipes() {
    const user = fbConfig.auth().currentUser.uid;
    const route = `/users/${user}/recipes`;
    this.clearError();
    const response = await this.callApi(route);
    return response.recipes;
  }

  async getRecipe(recipeId) {
    const route = `/recipes/${recipeId}`;
    this.clearError();
    const response = await this.callApi(route);
    return response;
  }

  async callApi(route, payload) {
    const idToken = await fbConfig.auth().currentUser.getIdToken(true);

    const data = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };

    if (payload) {
      data.body = JSON.stringify(payload);
    }

    const response = await fetch(config.apiUrl + route, data);
    const json = await response.json();

    // code key created if there's an error
    if (json.code) {
      this.error = this.codeToUserFacingMessage[json.code];
      throw new Error(this.error);
    }

    return json;
  }
}
