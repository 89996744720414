import React from 'react';
import './App.css';
import Home from './components/Home';
import Signup from './components/Signup';
import Login from './components/Login';
import Recipes from './components/Recipes';
import PrivateRoute from './components/PrivateRoute';
import RecipeDetail from './components/RecipeDetail';
import { AuthProvider } from './components/Auth';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <PrivateRoute path="/" component={Home} />
          <Switch>
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/recipes" component={Recipes} />
            <PrivateRoute exact path="/recipes/:id" component={RecipeDetail} />
          </Switch>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
