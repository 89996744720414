import React from 'react';
import { Api } from '../api/Api';
import { CircularProgress } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Table } from 'react-bootstrap';

class RecipeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      loading: true,
      recipe: {},
    };
  }

  componentDidMount() {
    this.getRecipe();
  }

  getRecipe = async () => {
    let api = new Api();

    try {
      this.setState({
        loading: false,
        recipe: await api.getRecipe(this.props.match.params.id),
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: api.error,
      });
    }
  };

  render() {
    if (!this.state.loading) {
      return (
        <Container className="Container">
          <h1 className="text-align-left">{this.state.recipe.name}</h1>
          <Table responsive>
            <thead>
              <tr className="text-align-left">
                <th>Amount</th>
                <th>Ingredient</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(this.state.recipe.ingredients).map(
                ([key, value]) => (
                  <tr className="text-align-left" key={key}>
                    <td>{value}</td>
                    <td>{key}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Container>
      );
    } else {
      return (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      );
    }
  }
}

export default RecipeDetail;
